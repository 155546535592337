@layer base,
tailwind-base,
primereact,
primereact-customs,
defaults,
tailwind-utilities,
components,
utilities,
tailwind-variants,
media,
something,
global-overwrite;


@layer tailwind-base {
  @tailwind base;
}

@import url("@node_modules/primereact/resources/themes/lara-light-cyan/theme.css");
@import url('@node_modules/primeicons/primeicons.css');

@layer primereact-customs {
  * {
    --button-color: rgb(77 77 77);
    --button-hover-color: rgb(19 195 195);
    --button-hover-shadow: rgb(50 50 50);

    .p-component,
    .p-button,
    .p-button-label {
      background-color: var(--button-color);
    }

    .p-button.component:active,
    .p.component:focus,
    .p-button:active,
    .p-button:focus {
      border-color: var(--button-hover-color);
      box-shadow: 0 0 0 2px var(--button-hover-shadow);
    }
  }
}

@layer tailwind-utilities {
  @tailwind components;
  @tailwind utilities;
}

@layer tailwind-variants {
  @tailwind variants;
}

@layer defaults {
  * {
    /* --primary-text-color: rgb(40,40,40); */
    --background-end-rgb: 255, 255, 255;
    --background-start-rgb: 214, 219, 220;

    --background: rgb(205 205 220);
    --cockie-consent-bg-color: rgb(49 134 213);
    --cookie-consent-buttton-bg-rgb: 237 207 76;
    --cookie-consent-buttton-bg-color: rgb(var(--cookie-consent-buttton-bg-rgb));
    --cookie-consent-buttton-text-color: var(--primary-text-color);
    --link-hover: rgb(230 230 230);
    --link: rgb(100 100 100);
    --primary-color: rgb(230 230 237);
    --primary-text-color: rgb(20 20 30);
    // --selection-background: rgba(111 119 164 / 0.804);
    --selection-background: var(--cookie-consent-buttton-bg-color);

    font-size: 12px;
    --text-xxs: 0.7rem;
    --text-xs: 1rem;

  }

  @media (min-width: 640px) {
    * {
      font-size: 16px;
    }
  }

  @media (min-width: 1200px) {
    * {
      font-size: 18px;
    }
  }

  [data-theme="dark"] {
    * {
      --background: rgb(28 28 34);
      --cockie-consent-bg-color: rgb(12 57 65);
      --link-hover: rgb(230 230 230);
      --link: rgb(200 200 200);
      --primary-color: rgb(37 38 41);
      --primary-text-color: rgb(235 235 242);
      // --cookie-consent-buttton-bg-color: rgb(134 115 27);
      --selection-background: rgba(var(--cookie-consent-buttton-bg-rgb) / 0.5);
    }
  }

  // @media (prefers-color-scheme: dark) {}

  body {
    margin: 0 !important;
    padding: 0 !important;
    color: var(--primary-text-color);

    background-color: var(--background);
    user-select: text;

    *::selection {
      background-color: var(--selection-background)
    }
  }

  // h1,
  // h2,
  // h3,
  // h4 {
  //   cursor: default;
  //   user-select: none;
  // }

  h1 {
    font-size: 2rem;
    padding: 1rem 0;
    font-weight: bold;
    padding-bottom: 2rem;
    font-family: 'Courier New', Courier, monospace;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  }

  h2 {
    font-size: 1.6rem;
    font-weight: bold;
  }

  h3 {
    font-size: 1.2rem;
  }

  p {
    text-align: justify;
    word-break: auto-phrase;
    hyphens: auto;
  }
}